import {createStore, combineReducers, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import peaksReducer from './reducers/peaks-reducer';
import servicesReducer from './reducers/services-reducer';
import projectsReducer from './reducers/projects-reducer';
import jobsReducer from './reducers/jobs-reducer';

const combinedReducers = combineReducers({
  peaks: peaksReducer,
  services: servicesReducer,
  projects: projectsReducer,
  jobs: jobsReducer,
});

const storeEnhancers = composeWithDevTools(
    applyMiddleware(thunk),
);

export const store = createStore(
    combinedReducers,
    {
      peaks: [],
      services: [],
      projects: [],
      jobs: [],
    },
    storeEnhancers,
);
