import axios from 'axios';
import {API_SOURCE} from '../utils/constants';
/*
* action types
*/
export const ADD_PROJECTS = 'projects:addProjects';

/*
* action creators
*/
export function addProjects(newProjects) {
  return {
    type: ADD_PROJECTS,
    payload: {
      projects: newProjects,
    },
  };
}
export function fetchProjects(projectId = '') {
  return (dispatch) => {
    return axios.get(API_SOURCE+'/projects/'+projectId)
        .then( (response) => {
          dispatch(addProjects(response.data));
        });
  };
}
