import React from 'react';
import {Link} from 'react-router-dom';
import './button.css';

function Button(props) {
  return (
    <Link to={props.to}
      className={'button '+props.className}
      onClick={props.onClick}
    >
      {props.children}
    </Link>
  );
}

export default Button;
