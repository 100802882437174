import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import {store} from './store';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import ScrollToTop from './utils/scroll-to-top';

import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';

import './assets/styles/index.css';
import RouteSwitcher from './RouteSwitcher';

library.add(fab, fas);


ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter basename={
        process.env.REACT_APP_DEPLOY === 'true' ?
          '' :
          process.env.REACT_APP_SUB_FOLDER || ''
      }>
        <ScrollToTop />
        <RouteSwitcher />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
process.env.REACT_APP_DEPLOY === 'true' ?
  serviceWorker.register() :
  serviceWorker.unregister();
