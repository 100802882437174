import axios from 'axios';
import {API_SOURCE} from '../utils/constants';
/*
* action types
*/
export const ADD_SERVICES = 'services:addServices';

/*
* action creators
*/
export function addServices(newServices) {
  return {
    type: ADD_SERVICES,
    payload: {
      services: newServices,
    },
  };
}
export function fetchServices(serviceId = '') {
  return (dispatch) => {
    return axios.get(API_SOURCE+'/services/'+serviceId)
        .then( (response) => {
          dispatch(addServices(response.data));
        });
  };
}
