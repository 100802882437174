import React, {Component} from 'react';
import PageHeader from './PageHeader';
import Footer from '../Footer';

import connectWithRouter from '../../utils/connect-with-router';

class BasePage extends Component {
  render() {
    return (
      <div className="page">
        <PageHeader title={this.props.title} />
        <section className="page__body">
          {this.props.children}
        </section>
        <Footer />
      </div>
    );
  }
}

export default connectWithRouter(BasePage);
