import {ADD_JOBS} from '../actions/jobs-actions';

export default function jobsReducer(state = [], {type, payload}) {
  switch (type) {
    case ADD_JOBS: {
      const newJobs = [...state];
      payload.jobs.forEach((job) => {
        let found = false;
        for (let i = 0; i < newJobs.length; i++) {
          if (newJobs[i].id === job.id) {
            found = true;
            newJobs[i] = job;
            break;
          }
        }
        if (!found) {
          newJobs.push(job);
        }
      });
      return newJobs;
    }
    default:
      return state;
  }
}
