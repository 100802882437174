import axios from 'axios';
import {API_SOURCE} from '../utils/constants';
/*
* action types
*/
export const ADD_JOBS = 'jobs:addJobs';

/*
* action creators
*/
export function addJobs(newJobs) {
  return {
    type: ADD_JOBS,
    payload: {
      jobs: newJobs,
    },
  };
}
export function fetchJobs(jobId = '') {
  return (dispatch) => {
    return axios.get(API_SOURCE+'/jobs/'+jobId)
        .then( (response) => {
          dispatch(addJobs(response.data));
        });
  };
}
