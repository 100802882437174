import {ADD_SERVICES} from '../actions/services-actions';

export default function servicesReducer(state = [], {type, payload}) {
  switch (type) {
    case ADD_SERVICES: {
      const newServices = [...state];
      payload.services.forEach((service) => {
        let found = false;
        for (let i = 0; i < newServices.length; i++) {
          if (newServices[i].id === service.id) {
            found = true;
            newServices[i] = service;
            break;
          }
        }
        if (!found) {
          newServices.push(service);
        }
      });
      return newServices;
    }
    default:
      return state;
  }
}
