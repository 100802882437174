import React, {Component} from 'react';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from '../Button';

import './navbar.css';
import logo from '../../assets/images/logo.png';
import logoInverted from '../../assets/images/logo-inverted.png';

import connectWithRouter from '../../utils/connect-with-router';

const menuItems = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'Services',
    link: '/services',
  },
  {
    name: 'Projects',
    link: '/projects',
  },
  {
    name: 'About Us',
    link: '/about-us',
  },
  {
    name: 'Career',
    link: '/career',
  },
];

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuCollapsed: true,
      invertBar: false,
      logo: logoInverted,
    };

    this.setNavBarMenuContainerRef = this.setNavBarMenuContainerRef.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleFooter = this.toggleFooter.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.toggleFooter);
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.toggleFooter);
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  setNavBarMenuContainerRef(node) {
    this.wrapperRef = node;
  }

  showMenu() {
    this.setState( () => {
      return {menuCollapsed: false};
    });
  }

  hideMenu() {
    this.setState( () => {
      return {menuCollapsed: true};
    });
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.hideMenu();
    }
  }

  toggleFooter(event) {
    if ( window.scrollY > 50 ) {
      this.setState( () => {
        return {
          invertBar: true,
          logo: logo,
        };
      });
    } else {
      this.setState( () => {
        return {
          invertBar: false,
          logo: logoInverted,
        };
      });
    }
  }

  render() {
    return (
      <div
        className={cn(
            'navbar container-fluid',
            {
              'navbar--inverted': this.state.invertBar,
            },
        )}
      >
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-3">
              <Link to="/">
                <img
                  className="navbar__logo"
                  src={this.state.logo}
                  alt="IT Tick logo"
                  title="IT Tick logo"
                />
              </Link>
            </div>
            <div className="col-6 d-md-none navbar__collapse-container">
              <Button
                onClick={this.showMenu}
                to="#"
                className={(
                  this.state.invertBar ?
                  'button--active' :
                  'button--inverted'
                )}
              >
                  Menu
              </Button>
            </div>
            <div
              className={cn(
                  'col-md-9',
                  'col-6',
                  'navbar__menu-container',
                  {
                    'navbar__menu-container--shown': (
                      !this.state.menuCollapsed
                    ),
                  },
              )}
              ref={this.setNavBarMenuContainerRef}
            >
              <ul className="navbar__menu">
                <li className="navbar__menu__item">
                  <span
                    className={cn(
                        'navbar__menu__item__link',
                        ' navbar__menu__item__link--active',
                        'd-md-none',
                    )}
                    onClick={this.hideMenu}
                  >
                    Collapse &#187;
                  </span>
                </li>
                {menuItems.map((item) => (
                  <li key={item.name} className="navbar__menu__item">
                    <Link
                      className="navbar__menu__item__link"
                      to={item.link}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
                <li className="navbar__menu__item">
                  <a
                    className="navbar__menu__item__link"
                    href="https://www.facebook.com/ittick.eg/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connectWithRouter(NavBar);
