import React, {Component} from 'react';

import background from '../../../assets/images/small-header.svg';
import NavBar from '../../NavBar';
import './header.css';

import connectWithRouter from '../../../utils/connect-with-router';

class PageHeader extends Component {
  render() {
    return (
      <div className="page-header container-fluid">
        <div className="page-header__background"
          style={{
            backgroundImage: 'url('+background+')',
          }}>
        </div>
        <div className="row">
          <div className="container">
            <div className="row">
              <NavBar />
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="page-header__title">{this.props.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connectWithRouter(PageHeader);
