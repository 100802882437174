import {ADD_PROJECTS} from '../actions/projects-actions';

export default function projectsReducer(state = [], {type, payload}) {
  switch (type) {
    case ADD_PROJECTS: {
      const newProjects = [...state];
      payload.projects.forEach((project) => {
        let found = false;
        for (let i = 0; i < newProjects.length; i++) {
          if (newProjects[i].id === project.id) {
            found = true;
            newProjects[i] = project;
            break;
          }
        }
        if (!found) {
          newProjects.push(project);
        }
      });
      return newProjects;
    }
    default:
      return state;
  }
}
