import {ADD_PEAKS} from '../actions/peaks-actions';

export default function peaksReducer(state = [], {type, payload}) {
  switch (type) {
    case ADD_PEAKS: {
      const newPeaks = [...state];
      payload.peaks.forEach((peak) => {
        let found = false;
        for (let i = 0; i < newPeaks.length; i++) {
          if (newPeaks[i].id === peak.id) {
            found = true;
            newPeaks[i] = peak;
            break;
          }
        }
        if (!found) {
          newPeaks.push(peak);
        }
      });
      return newPeaks;
    }
    default: {
      return state;
    }
  }
}
