import axios from 'axios';
import {API_SOURCE} from '../utils/constants';
/*
* action types
*/
export const ADD_PEAKS = 'peaks:addPeaks';

/*
* action creators
*/
export function addPeaks(newPeaks) {
  return {
    type: ADD_PEAKS,
    payload: {
      peaks: newPeaks,
    },
  };
}
export function fetchPeaks(serviceId = '') {
  return (dispatch) => {
    return axios.get(API_SOURCE+'/peaks/'+serviceId)
        .then( (response) => {
          dispatch(addPeaks(response.data));
        });
  };
}
