import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import loadable from '@loadable/component';
import MetaTags from 'react-meta-tags';
import BasePage from './components/BasePage';
import PageLoading from './components/PageLoading';
import {DEFAULT_TITLE} from './utils/constants';

const loadbleOptions = {fallback: <PageLoading />};
const Home = loadable(() => import('./routes/Home'), loadbleOptions);
const About = loadable(() => import('./routes/About'), loadbleOptions);
const Services = loadable(() => import('./routes/Services'), loadbleOptions);
const Service = loadable(() => import('./routes/Service'), loadbleOptions);
const Projects = loadable(() => import('./routes/Projects'), loadbleOptions);
const Project = loadable(() => import('./routes/Project'), loadbleOptions);
const Career = loadable(() => import('./routes/Career'), loadbleOptions);
const Job = loadable(() => import('./routes/Job'), loadbleOptions);
const PrivacyPolicy =
  loadable(() => import('./routes/PrivacyPolicy'), loadbleOptions);

export default class RouteSwitcher extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about-us">
          <About />
        </Route>
        <Route path="/services/:title">
          <Service />
        </Route>
        <Route path="/services">
          <Services />
        </Route>
        <Route path="/projects/:title">
          <Project />
        </Route>
        <Route path="/projects">
          <Projects />
        </Route>
        <Route path="/career/:title">
          <Job />
        </Route>
        <Route path="/career">
          <Career />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="*">
          <BasePage title="Page can't be found!">
            <MetaTags>
              <title>404 Not Found - {DEFAULT_TITLE}</title>
            </MetaTags>
          </BasePage>
        </Route>
      </Switch>
    );
  }
}

