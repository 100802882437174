import React from 'react';
import teamImage from '../../assets/images/fotoer.svg';
import './footer.css';

function Footer() {
  return (
    <footer className="footer container-fluid">
      <img src={teamImage} alt="IT Tick Team" title="IT Tick Team" />
    </footer>
  );
}

export default Footer;
