import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import './page-loading.css';

export default function PageLoading() {
  return (
    <div className="page-loading">
      <FontAwesomeIcon icon={['fas', 'circle-notch']} spin size="6x" />
    </div>
  );
}
